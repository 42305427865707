:root {
  --overlay-color: hsla(34, 45%, 68%, 0.25);
}

:root *:focus {
  outline: none;
}

.lightmode {
  --primary-clr: hsl(35, 93%, 45%);
  --bg-clr: hsl(35, 93%, 45%);
  --box-clr: #fff;
  --tick-clr: #fff;
  --text-clr: hsl(30, 3%, 29%);
  --text-clr-on-primary: #eeeeee;
  --danger: hsl(14, 46%, 48%);
  --ok: hsl(105, 46%, 48%);
  color: var(--text-clr);
}

.darkmode {
  --primary-clr: hsl(35, 93%, 68%);
  --bg-clr: #121212;
  --box-clr: hsl(33, 14%, 15%);
  --tick-clr: hsl(33, 14%, 15%);
  --text-clr: #eeeeee;
  --text-clr-on-primary: #121212;
  --danger: hsl(14, 46%, 68%);
  --ok: hsl(105, 46%, 68%);
  color: var(--text-clr);
}

/* roboto-condensed-300 - latin-ext_latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 300;
  src: url("fonts/roboto-condensed-v19-latin-ext_latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("fonts/roboto-condensed-v19-latin-ext_latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/roboto-condensed-v19-latin-ext_latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("fonts/roboto-condensed-v19-latin-ext_latin-300.woff") format("woff"),
    /* Modern Browsers */
      url("fonts/roboto-condensed-v19-latin-ext_latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("fonts/roboto-condensed-v19-latin-ext_latin-300.svg#RobotoCondensed")
      format("svg"); /* Legacy iOS */
}

/* roboto-condensed-300italic - latin-ext_latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 300;
  src: url("fonts/roboto-condensed-v19-latin-ext_latin-300italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("fonts/roboto-condensed-v19-latin-ext_latin-300italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("fonts/roboto-condensed-v19-latin-ext_latin-300italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("fonts/roboto-condensed-v19-latin-ext_latin-300italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("fonts/roboto-condensed-v19-latin-ext_latin-300italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("fonts/roboto-condensed-v19-latin-ext_latin-300italic.svg#RobotoCondensed")
      format("svg"); /* Legacy iOS */
}

/* roboto-condensed-regular - latin-ext_latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/roboto-condensed-v19-latin-ext_latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("fonts/roboto-condensed-v19-latin-ext_latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("fonts/roboto-condensed-v19-latin-ext_latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("fonts/roboto-condensed-v19-latin-ext_latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("fonts/roboto-condensed-v19-latin-ext_latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("fonts/roboto-condensed-v19-latin-ext_latin-regular.svg#RobotoCondensed")
      format("svg"); /* Legacy iOS */
}

/* roboto-condensed-italic - latin-ext_latin */
@font-face {
  font-family: "Roboto Condensed";
  font-style: italic;
  font-weight: 400;
  src: url("fonts/roboto-condensed-v19-latin-ext_latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("fonts/roboto-condensed-v19-latin-ext_latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("fonts/roboto-condensed-v19-latin-ext_latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("fonts/roboto-condensed-v19-latin-ext_latin-italic.woff")
      format("woff"),
    /* Modern Browsers */
      url("fonts/roboto-condensed-v19-latin-ext_latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("fonts/roboto-condensed-v19-latin-ext_latin-italic.svg#RobotoCondensed")
      format("svg"); /* Legacy iOS */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media only screen and (min-width: 700px) {
  html {
    font-size: 1.25rem;
  }
}

body {
  align-items: center;
  justify-content: center;
  background-color: hsl(0, 0%, 90%);
  font-family: "Roboto Condensed";
  overflow: hidden;
}

#root {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  position: relative;
}

#root > div {
  width: 100%;
  height: 100%;
}

ul {
  list-style-type: square;
}

.App {
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 900px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background-color: var(--bg-clr);
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.bottom-section::after {
  content: "";
  position: absolute;
  display: block;
  bottom: calc(var(--vh, 1vh) * -10);
  height: calc(var(--vh, 1vh) * 10);
  width: 100vw;
  left: -1.5em;
  background-color: var(--box-clr);
}

.App.lightmode {
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0) 0%,
    hsla(50, 100%, 50%, 0.33) 25%
  );
}

.App.fullscreen {
  max-width: 100vw !important;
  width: 100vw;
}

.image-section {
  padding-top: 1rem;
  height: 12rem;
  flex-shrink: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  transition: transform 300ms ease-in-out 3ms;
  will-change: transform;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1rem;
  width: 1.5em;
}

.expanded {
  transform: translateY(33vh);
}

.image-section {
  color: #eee;
}

.image-section .placeholder-container {
  width: 50%;
  text-align: center;
}

.image-section .placeholder-title {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.image-section .placeholder-body {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1rem;
}

.hint {
  margin-top: .5rem;
  padding: .5rem;
  border-radius: .5rem;
  background-color: var(--danger);
  color: var(--text-clr-on-primary);
}

#bigDropzone {
  opacity: 0;
  top: 0;
  z-index: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.bottom-section {
  border-radius: 1.8rem 1.8rem 0 0;
  box-shadow: 0 0.15rem 0.15rem rgba(0, 0, 0, 0.12);
  padding: 1.5em;
  display: flex;
  flex-shrink: 1;
  flex-grow: 1;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: scroll;
  transition: transform 300ms ease-in-out 3ms;
}

.bottom-section {
  background-color: var(--box-clr);
}

.bottom-section.hidden {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.manual {
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 7rem;
}

.manual-item {
  display: flex;
  flex-direction: row;
  padding: 0.7rem 1rem;
  align-items: center;
  overflow: hidden;
}

.manual-item-text {
  flex-grow: 1;
  font-size: 1.2rem;
  font-weight: 300;
  padding-left: 1.5em;
}

.manual-item svg {
  min-width: 2.5rem;
  width: 2.5rem;
}

.bottomButtons {
  z-index: 2;
  position: absolute;
  left: 0;
  width: 100%;
  margin-left: 50%;
  height: 6rem;
  bottom: calc(1rem + constant(safe-area-inset-bottom));
  bottom: calc(1rem + env(safe-area-inset-bottom));
}

.topBar {
  width: 100%;
  height: 0px;
  position: relative;
  flex-grow: 0;
  top: 0;
}

.topContent {
  position: relative;
  flex-grow: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  transition: transform 300ms ease-in-out 3ms;
  will-change: transform;
}

.newImageButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3.5rem;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  position: absolute;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.25);
  transform-origin: center;
  transform: translateX(-50%);
}

.galleryButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2rem;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, 0.25);
  position: absolute;
  margin-left: -5rem;
  margin-top: 2rem;
  transform-origin: center;
  transform: translateX(-50%);
}

.hidden.bottomButtons {
  display: none;
}

.littleSpinner {
  box-sizing: border-box;
  overflow: hidden;
  padding: 0px !important;
  margin-left: 0.5em;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid var(--text-clr-on-primary);
  animation: spin 1s linear infinite;
}

.primary {
  background-color: var(--primary-clr);
  border-color: var(--text-clr-on-primary);
  color: var(--text-clr-on-primary);
}

input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.scrollbarless {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.scrollbarless::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.images {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  max-height: 100%;
  flex-grow: 0;
}

.imgContainer {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 1rem 0rem;
}

.uploadedImage {
  border-radius: 1.5rem;
  height: 6rem;
  max-height: 100%;
}

.goToInput {
  border-radius: 1.5rem;
  height: 6rem;
  width: 6rem;
  min-width: 6rem;
  max-height: 100%;
  border: 0.15em dashed hsla(0, 0%, 100%, 0.5);
  background-color: hsla(0, 0%, 100%, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 2;
  margin: 1rem 0.5rem;
  position: relative;
}

.goToInput::after {
  content: "+";
  font-family: "Times New Roman", Times, serif;
  font-size: 5rem;
  color: rgba(255, 255, 255, 0.7);
}

.top-btn {
  position: absolute;
  transform: translateY(-3em);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  left: 0;
  z-index: 2;
}

.btn {
  border: 0.15em solid;
  box-shadow: 0 0.15rem 0.15rem rgba(0, 0, 0, 0.25);
  border-radius: 1em;
  height: 2em;
  font-size: 1.3rem;
  padding: 0.5em 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  margin: 0.2em;
}

.id.btn {
  padding: 0 2em;
}

.reset.btn {
  padding: 0 1em;
}

.btn svg {
  height: 1.3em;
}

.btn > span {
  flex-grow: 1;
  padding: 1rem;
}

.cropContainer {
  position: absolute;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.cropContainer > .cropper {
  flex-grow: 1;
}

.cropContainer > .editing {
  display: flex;
  width: 100%;
}

.cropContainer > .editing .slider {
  flex-grow: 1;
  padding: 0 1rem;
}

.editing .MuiSlider-root {
  color: white;
}

.cropContainer > .editing svg {
  width: 3.5rem;
  height: 2.5rem;
}

.cropContainer > .buttons {
  display: flex;
  padding-bottom: calc(1rem + constant(safe-area-inset-bottom));
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
}

.cropContainer > .buttons > div {
  padding: 0.5rem 2rem;
  margin: 1rem;
}

.cropContainer svg {
  height: 1.5rem;
  cursor: pointer;
}

.modal {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.72);
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.modal.invisible {
  z-index: -2;
  opacity: 0;
  transition: opacity 100ms linear 203ms, z-index 0ms linear 303ms;
  will-change: opacity, z-index;
}

.modal.visible {
  opacity: 1;
  z-index: 3;
  transition: opacity 100ms linear 3ms;
  will-change: opacity;
}

.modal .content {
  width: 100%;
  z-index: 300;
  padding: 4rem 2rem 2rem 2rem;
  display: flex;
  line-height: 1.4em;
  flex-direction: column;
  transition: transform 300ms ease-in-out 3ms;
  will-change: transform;
}

.modal .content .scrollable {
  overflow-y: scroll;
  overflow-x: hidden;
}

.modal .content {
  background-color: var(--box-clr);
}

#modal .content {
  position: relative;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  font-size: 1.2rem;
  font-weight: 400;
}

#modal p {
  margin-bottom: 1.2em;
}

#modal.invisible .content {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

#menu .content {
  top: 0;
  border-radius: 0 0 1.7rem 1.7rem;
  transition: transform 300ms ease-in-out 3ms;
  will-change: transform;
}

#menu.invisible .content {
  transform: translateY(-100%);
}

.modalHeader > svg {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}

.modal .modalHeader {
  border-bottom: 1px solid hsla(0, 0%, 50%, 0.5);
  text-align: end;
}

.aboutLogo {
  height: 5rem;
  width: 50%;
  padding: 0.5em;
}

.darkmode .light {
  display: none;
}

.lightmode .dark {
  display: none;
}

.aboutHeader {
  width: 100%;
  height: 4em;
  padding: 1em;
}

hr {
  height: 1px;
  border: none;
  background-color: hsla(0, 0%, 50%, 0.5);
}

.menuItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid hsla(0, 0%, 50%, 0.5);
  padding: 1rem 0;
  font-size: 1.2rem;
  font-weight: 400;
  cursor: pointer;
}

.menuItem:active {
  background-color: var(--overlay-color);
}

.menu-icon {
  cursor: pointer;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 1rem;
  margin-top: -0.4rem;
  z-index: 1;
}

.menu-icon.hidden {
  display: none;
}

#menu a {
  text-decoration: none;
  color: inherit;
}

.resultRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.2rem 0;
  border-bottom: 1px solid hsla(0, 0%, 50%, 50%);
  cursor: pointer;
  word-break: break-word;
}

.resultDonut {
  width: 4.5rem;
  margin-right: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.darkmode .resultDonut svg {
  opacity: 0.66;
}

.resultDonut .percentage {
  position: absolute;
  font-size: 1em;
}

.intro {
  font-size: 1.1em;
  font-weight: bold;
}

.resultRow .vernacular {
  font-size: 1.6em;
}

.hyphenate {
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  word-wrap: break-word;
}

.danger {
  background-color: var(--danger);
  border-color: var(--text-clr-on-primary);
  color: var(--text-clr-on-primary);
  border-radius: .4em;
  padding: .2em .2em .2em 1em;
  margin-top: .4em;
}

.ok {
  background-color: var(--ok);
  border-color: var(--text-clr-on-primary);
  color: var(--text-clr-on-primary);
}

.danger svg {
  margin-right: 0.3em;
}

#modal .vernacular {
  font-size: 2em;
  line-height: 1em;
}

#modal .resultLabels {
  border-bottom: 1px solid hsla(0, 0%, 50%, 50%);
  padding: 1em 0;
  margin-bottom: 2em;
  flex-grow: 0;
}

#modal .resultDescription {
  font-size: 1em;
  flex-grow: 1;
}

#modal .resultDescription > * {
  margin-top: 1em;
}

#modal .resultActions {
  margin-top: 1em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#modal .btn {
  font-size: 0.9rem;
}

.italics {
  font-style: italic;
}

.resultRow .scientific {
  font-size: 1em;
  line-height: 1.5em;
}

#modal .scientific {
  font-size: 1.2em;
  line-height: 1em;
  margin-top: 0.5em;
}

.extendedManual svg {
  width: 3rem;
  margin-right: 0.75em;
  float: left;
}

.extendedResult {
  display: flex;
  flex-direction: column;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: underline;
}

a.btn {
  text-decoration: none;
  color: var(--text-clr-on-primary);
}

.scientific {
  font-style: italic;
  font-weight: 400;
}

.group {
  font-size: 1em;
  font-variant: small-caps;
}

.resultLabels {
  flex-grow: 1;
  flex-shrink: 1;
  margin-left: 1rem;
}

.tick {
  background-color: var(--tick-clr);
}

.chevron-right {
  flex-shrink: 0;
  color: hsla(0, 0%, 50%, 75%);
}

.chevron-right svg {
  height: 3em;
}

.loading .imgContainer {
  animation: blink 1s ease-in-out infinite;
}

@keyframes blink {
  50% {
    opacity: 0.5;
  }
}

@keyframes spin {
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
